import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import { Modal } from './../components/Modal';
import { PortfolioDetail } from './../components/PortfolioDetail';
import Button from './../components/Button';
import PortfolioItems from './../content/Portfolio';
import { reinitState } from '../redux/actions';
import { connect } from 'react-redux';

class Portfolio extends Component {
  constructor() {
    super();

    this.state = {
      portfolioItems: PortfolioItems.result,
      modalOpen: false,
      activePortfolioItem: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(reinitState({ reinit: false }));
  }

  onOpenModal = (e, i) => {
    document.body.classList.add('modal-open-overlay');
    let portfolioItem = this.state.portfolioItems[i];
    this.setState({
      ...this.state,
      modalOpen: true,
      activePortfolioItem: portfolioItem,
    });
  };

  onHideModal = () => {
    document.body.classList.remove('modal-open-overlay');
    this.setState({ modalOpen: false, slideChanging: true });
  };

  nextSlide = () => {
    this.slider.slickNext();
  };
  render() {
    const { portfolioItems, modalOpen, activePortfolioItem, slideChanging } =
      this.state;
    const { images, settings } = this.props;

    let defaultSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
      ],
      customPaging: (i) => (
        <div className="slick-numbers">
          <span>{i == 0 ? 'INTRO' : '0' + i}</span>
        </div>
      ),
      //afterChange: () => this.setState(state => ({ slideChanging: false })),
      //beforeChange: () => //this.setState(state => ({ slideChanging: true })),
    };

    return (
      <>
        <Helmet>
          <title>Explore the portfolio - gedlow</title>
        </Helmet>
        <Slider
          ref={(c) => (this.slider = c)}
          className={
            'slider-portfolio ' +
            (slideChanging ? 'slide-loaded' : 'slide-loading')
          }
          {...(settings ? settings : defaultSettings)}
        >
          <div>
            <div className="page-hero">
              <div className="page-hero__image animation__image-covered"></div>

              <div className="page-hero__content">
                <div className="title-wrapper mt-5 mb-5">
                  <p className="title-wrapper__subtitle">
                    PORTF<span className="colour-accent">O</span>LIO
                  </p>
                  <h1 className="title-wrapper__title animation__text-covered animation__colour-accent">
                    Explore my <br />
                    projects<span>.</span>
                  </h1>
                  <p className="title-wrapper__description animation__text-covered">
                    From Apps to E-Commerce websites. Check out some of my
                    completed projects.
                  </p>
                  <div className="mt-3 mb-2">
                    <Button
                      onClick={(e) => this.nextSlide()}
                      colour="ghost"
                      label="Start >"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {portfolioItems.map((item, index) => (
            <div key={index}>
              <div className="page-hero">
                <div
                  className="page-hero__image animation__image-covered"
                  style={{ backgroundImage: 'url(' + item.coverImage + ')' }}
                ></div>

                <div className="page-hero__content">
                  <div className="title-wrapper mt-5 mb-5">
                    <p className="title-wrapper__subtitle">
                      PORTF<span className="colour-accent">O</span>LIO
                    </p>
                    <h1 className="title-wrapper__title animation__text-covered animation__colour-accent">
                      {item.title}
                      <span>.</span>
                    </h1>
                    <p className="title-wrapper__description animation__text-covered">
                      {item.description}
                    </p>

                    <div className="mt-3 mb-2">
                      <Button
                        onClick={(e) => this.onOpenModal(e, index)}
                        label="More Details"
                      />{' '}
                      <Button
                        onClick={(e) => this.nextSlide()}
                        colour="ghost"
                        label="Next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <Modal show={modalOpen} handleClose={this.onHideModal}>
          {activePortfolioItem && (
            <PortfolioDetail
              data={activePortfolioItem}
              handleClose={this.onHideModal}
            />
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loaded: state?.loaded,
  reinit: state?.reinit,
});

export default connect(mapStateToProps)(Portfolio);
