export default {
  result: [
    {
      slug: 'kcom',
      title: 'KCOM App',
      description:
        'An App built for tablet and made available at school careers events. "Place a wireless router" game to engage students, followed by a questionnaire to reveal matching job role types within the KCOM organisation.',
      technology: ['React Native', 'Android Studio', 'Expo CLI', 'Photoshop'],
      coverImage: require('./../assets/images/portfolio/kcom2.jpg'),
      detailType: 'carousel',
      detailImages: [
        require('./../assets/images/portfolio/kcom/1-res.jpg'),
        require('./../assets/images/portfolio/kcom/2.jpg'),
        require('./../assets/images/portfolio/kcom/3.jpg'),
        require('./../assets/images/portfolio/kcom/4.jpg'),
        require('./../assets/images/portfolio/kcom/5.jpg'),
        require('./../assets/images/portfolio/kcom/6.jpg'),
      ],
    },
    {
      slug: 'chitter-chatter',
      title: 'Chitter Chatter',
      coverImage: require('./../assets/images/portfolio/cc2.jpg'),
      detailType: 'scroller',
      technology: ['Photoshop', 'inVision'],
      detailImages: [require('./../assets/images/portfolio/cc/home.jpg')],
      description:
        'A new website was required for the one of the UK’s leading independent mobile retailers. My task was to design a new website which would stand out from the crowd.',
    },
    {
      slug: 'android-homme',
      title: 'Android Homme',
      coverImage: require('./../assets/images/portfolio/ah3.jpg'),
      detailType: 'carousel',
      detailImages: [
        require('./../assets/images/portfolio/ah/slide-1.jpg'),
        require('./../assets/images/portfolio/ah/slide-2.jpg'),
        require('./../assets/images/portfolio/ah/slide-3.jpg'),
        require('./../assets/images/portfolio/ah/slide-4.jpg'),
      ],
      technology: ['PHP', 'WooCommerce', 'Twig', 'Timber', 'SASS'],
      description:
        'Los Angeles. Bold. Detailed. Inspired. Android Homme is a luxury sneaker brand represented in some of the world’s most premium retailers. The task was to turn provided design into a fully working website.',
    },
    {
      slug: 'perfect-home',
      title: 'Perfect Home',
      coverImage: require('./../assets/images/portfolio/ph/ph-cover.jpeg'),
      detailType: 'scroller',
      technology: ['PhotoShop', 'inVision'],
      detailImages: [require('./../assets/images/portfolio/ph/ph-detail.jpeg')],
      description:
        "The website for Perfect Home, it's a balance between functionality and aesthetics. Get ready to admire!",
    },
    {
      slug: 'snow-options',
      title: 'Happiness is ski shaped',
      coverImage: require('./../assets/images/portfolio/so/so-cover.jpeg'),
      detailType: 'scroller',
      technology: ['Figma', 'CMS', 'React', 'Redux', 'SCSS', 'CI/CD'],
      detailImages: [require('./../assets/images/portfolio/so/so.jpeg')],
      description:
        'A website design and build for the ski holidays company. Snow Options is a specialist travel consultant offering traditional catered or self-catered ski packages and bespoke solutions for ski holidays in America and Europe.',
    },
    {
      slug: 'craftwork-upholstery',
      title: 'Craftwork',
      coverImage: require('./../assets/images/portfolio/cu/front.jpg'),
      detailType: 'scroller',
      technology: ['October CMS', 'eCommerce', 'Git', 'Twig', 'SCSS', 'PHP'],
      detailImages: [require('./../assets/images/portfolio/cu/detail.jpg')],
      description:
        'Craftwork Upholstery manufactures contract furniture. The task was to develop an eCommerce shop on the award-winning OctoberCMS.',
    },
    // {
    //   slug: 'the-hidden-symbol',
    //   title: 'The Hidden Symbol',
    //   coverImage: require('./../assets/images/portfolio/hs/hs2.jpg'),
    //   detailType: 'scroller',
    //   technology: [
    //     'Photoshop',
    //     'inVision',
    //     'WordPress',
    //     'Timber',
    //     'Twig',
    //     'SCSS',
    //   ],
    //   detailImages: [require('./../assets/images/portfolio/hs/detail.jpg')],
    //   description:
    //     'The Hidden Symbol is the tattoo studio with over 10 years experience in making peoples dreams a reality with ink. The task was to design & build a brand new website with the given brand guidelines.',
    // },

    {
      slug: 'elite-dog-groomers',
      title: 'Elite Dog Groomers',
      coverImage: require('./../assets/images/portfolio/edg/front.jpg'),
      detailType: 'scroller',
      technology: [
        'Photoshop',
        'inVision',
        'WordPress',
        'Timber',
        'Twig',
        'SCSS',
      ],
      detailImages: [require('./../assets/images/portfolio/edg/detail.jpg')],
      description:
        'Every pet deserves celebrity care. Elite Dog Groomers is a professional and friendly dog grooming salon based in Chorley. The task was to design & develop a brand new website for the business.',
    },
  ],
};
