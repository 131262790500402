import React, { Component } from "react";

export default class ImageScroller extends Component {
    constructor() {
        super();

        this.state = {
            overlay: true
        };
    }
    
    componentDidMount() {
        this.hideScrollerOverlay().then(() => this.setState({ overlay: false }));
    }

    componentWillUnmount() {
        this.setState({ overlay: true });
    }

    hideScrollerOverlay() {
        return new Promise((resolve) => setTimeout(() => resolve(), 1700));
    }

    render() {
        const { overlay } = this.state;
        const { images } = this.props;

        return (
            <div className="image-scroller">
                {overlay && (
                    <div className="scroller-overlay">
                        <div className="mouse-icon">
                            <div className="scroll"></div>
                        </div>
                    </div>
                )}
                {images.map(function(item, i) {
                return (
                    <img
                        key={i}
                        src={item}
                    />
                )
                })}
            </div>
        );
    }
}