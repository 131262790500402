import React from 'react';
import { Helmet } from 'react-helmet';

import Button from './../components/Button';
import Carousel from './../components/Slider';
import Clients from './../content/Clients';
import { Link } from 'react-router-dom';
import './../assets/scss/main.scss';
import './../assets/scss/pages/home.scss';
import { connect } from 'react-redux';
//import { reinitState } from '../redux/actions';
//import { useHistory } from 'react-router-dom';

function Frontpage(props) {
  //const history = useHistory();
  const transition = () => {
    document.body.querySelector('.menu-open').click();
    // props.dispatch(reinitState({ reinit: true }));
    // setTimeout(() => {
    //   history.push('/portfolio');
    // }, 1900);
  };
  return (
    <>
      <Helmet>
        <title>Creative Digital Developer - gedlow</title>
      </Helmet>
      <div className="page-hero page-hero--homepage">
        <div className="page-hero__image animation__image-covered"></div>

        <div className="page-hero__content">
          <div className="title-wrapper">
            <p className="title-wrapper__subtitle">
              GEDL<span className="colour-accent">O</span>W
            </p>
            <h1 className="title-wrapper__title animation__text-covered animation__colour-accent  mt-3">
              Creative Digital Solutions<span>.</span>
            </h1>
          </div>
          <p className="animation__text-covered">
            Hello! I am a technically savvy Full Stack Web Designer / Developer
            who has multiple years of digital development expertise behind.
            Check out my portfolio and get in touch!
          </p>

          <div className="mt-3 mb-4">
            <Link to="#" onClick={() => transition()}>
              <Button colour="ghost" label="Explore" />
            </Link>
          </div>

          <h2 className="animation__text-covered animation__colour-accent mb-2">
            Join my fan club
          </h2>
          <div className="slider-clients">
            <Carousel
              settings={{
                infinite: true,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 1,
                nav: false,
                responsive: [
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 1,
                      autoplay: true,
                      speed: 2000,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                      autoplay: true,
                      speed: 2000,
                    },
                  },
                ],
              }}
              images={Clients.images}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loaded: state.loaded,
  reinit: state.reinit,
});

export default connect(mapStateToProps)(Frontpage);
