export default {
  images: [
    require('./../assets/images/clients/dm.png'),
    require('./../assets/images/clients/nhs1.png'),
    require('./../assets/images/clients/jd.png'),
    require('./../assets/images/clients/sky.png'),
    require('./../assets/images/clients/kcom.png'),
    require('./../assets/images/clients/cc.png'),
  ],
};
