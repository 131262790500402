import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from './../components/ContactForm';
import './../assets/scss/main.scss';

class Contact extends Component {

  render() {

    return (
        <>
            <Helmet>
            <title>Contact - gedlow</title>
            </Helmet>
            <div className="page-hero">
                <div className="page-hero__image animation__image-covered"></div>

                <div className="page-hero__content">
                    <div className="title-wrapper">
                        <p className="title-wrapper__subtitle">GEDL<span className="colour-accent">O</span>W</p>
                        <h1 className="title-wrapper__title animation__text-covered animation__colour-accent">Get In Touch<span>.</span></h1>
                    </div>
                    <ContactForm />

                </div>
            </div>
        </>
    );
  }
}

export default Contact;
