import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nav2 from './../components/site/Nav2';
import Loading from './../components/site/Loading';

class Default extends Component {
  render() {
    const { loaded, reinit } = this.props;
    const isLoading = () => {
      console.log({ loaded, reinit });
      let t = false;
      if (loaded) {
        t = true;
      }
      if (reinit) {
        t = true;
      }

      if (loaded && reinit) {
        t = false;
      }

      return t;
    };
    return (
      <div
        className={
          'page-wrapper ' + (isLoading() ? 'state-ready' : 'state-loading')
        }
      >
        <Loading />
        <Nav2 />
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loaded: state?.loaded,
  reinit: state?.reinit,
});

export default connect(mapStateToProps)(Default);
