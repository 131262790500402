import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadingState, reinitState } from '../../redux/actions';
import '../../assets/scss/loading.scss';

class Loading extends Component {
  componentDidMount() {
    this.demoAsyncCall().then(() => {
      this.props.dispatch(loadingState({ loaded: true }));
      this.props.dispatch(reinitState({ reinit: false }));
    });
  }

  demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }

  render() {
    const { loaded, reinit } = this.props;

    console.log('tjis.props', this.props);
    return (
      <div className={'preloader ' + (loaded ? 'loaded' : '')}>
        <div className="preloader-container">
          <h1>Ged Laucius</h1>
          <div className="progress-line-container">
            <div className="progress-line"></div>
          </div>
          <h1>Portfolio</h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loaded: state?.loaded,
  reinit: state?.reinit,
});

export default connect(mapStateToProps)(Loading);
