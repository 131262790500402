import React, { Component } from 'react';

import './../assets/scss/main.scss';

class About extends Component {

  render() {

    return (
        <div className="aligner">
            <div className="aligner-item">
                About
            </div>
        </div>
    );
  }
}

export default About;
