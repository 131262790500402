import React, { Component } from "react";
import Slider from "react-slick";

export default class Carousel extends Component {
  render() {
    const { images, settings } = this.props;

    let defaultSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
      <>
        <Slider ref={c => (this.slider = c)} {...settings ? settings : defaultSettings }>
          {images.map(function(item, i) {
            return (
              <div className="animation__image-covered" key={i}>
                <img src={item} />
              </div>
            )
          })}
        </Slider>
      </>
    );
  }
}