import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Nav() {
  const [isNavOpened, setNavOpened] = useState(false);
  let location = useLocation();
  const Transition = () => setNavOpened(false);

  useEffect(() => {
    //console.log(location);
  });

  return (
    <>
      <div
        className="menu-open pointer-large"
        onClick={() => setNavOpened(true)}
      >
        <span className="hamburger"></span>
      </div>
      <nav
        className={'nav-container dark-bg-1 ' + (isNavOpened ? 'active' : '')}
      >
        <div className="nav-logo">
          <img src={require('../../assets/images/logo.png')} alt="logo" />
        </div>

        <div
          className="menu-close pointer-large"
          onClick={() => setNavOpened(false)}
        ></div>

        <ul className="nav-menu dark-bg-1">
          <li className="nav-box nav-bg-change active">
            <Link to="/" onClick={Transition} className="nav-link">
              <span
                className={
                  'nav-btn ' + (location.pathname === '/' ? 'active' : '')
                }
                data-text="About"
              >
                About
              </span>
            </Link>

            <div className="nav-bg"></div>
          </li>

          <li className="nav-box nav-bg-change">
            <Link to="/portfolio" onClick={Transition} className="nav-link">
              <span
                className={
                  'nav-btn ' +
                  (location.pathname === '/portfolio' ? 'active' : '')
                }
                data-text="Portfolio"
              >
                Portfolio
              </span>
            </Link>
            <div className="nav-bg"></div>
          </li>

          <li className="nav-box nav-bg-change">
            <Link to="/contact" onClick={Transition} className="nav-link">
              <span
                className={
                  'nav-btn ' +
                  (location.pathname === '/contact' ? 'active' : '')
                }
                data-text="Contact"
              >
                Contact
              </span>
            </Link>
            <div className="nav-bg"></div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Nav;
