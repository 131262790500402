import React, { Component } from 'react';
import Button from './../components/Button';

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            message: '',
            email: '',
            sent: false
        }
    }

    handleSubmit(e) {
        console.log('s');
        e.preventDefault();

        fetch('https://www.lauci.us/send.php',{
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => (response.json()))
        .then((response)=>{
            if (response.status === 'success') {
                alert("Message Sent."); 
                this.resetForm()
            } else if (response.status === 'fail') {
                alert("Message failed to send.")
            }
        });
    }

    render() {
        return (
           <form className="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
               <div className="row">
                   <div className="grid grid--md-6 grid--lg-6">
                       <input type="text" name="name" placeholder="Your Name" value={ this.state.name } onChange={e => this.setState({ name: e.target.value})} required />
                   </div>
                   <div className="grid grid--md-6 grid--lg-6">
                       <input type="email" name="email" placeholder="Email Address" value={ this.state.email } onChange={(e) => this.setState({ email: e.target.value})} required />
                   </div>
               </div>

               <div className="row">
                   <div className="grid grid--md-12 grid--lg-12">
                       <textarea name="message" placeholder="Your Message" value={ this.state.message } onChange={e => this.setState({ message: e.target.value})}></textarea>
                   </div>
               </div>

               <div className="row">
                   <div className="grid">
                        <div className="mb-2"><button type="submit" className="btn btn-accent">Submit</button></div>
                   </div>
               </div>
           </form>
        );
    }
}

export default ContactForm;