import { LOADED, REINIT } from '../constants/action-types';

const initialState = {
  loaded: false,
  reinit: false,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOADED:
      return {
        ...state,
        ...action.payload,
      };

    case REINIT:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default rootReducer;
