import React, { Component } from 'react';
import '../assets/scss/buttons.scss';


class Button extends Component {
    render() {
        return (
            <a href="#" {...this.props} className={"btn " + (this.props.colour ? 'btn-' + this.props.colour : 'btn-accent' )}>{ this.props.label }</a>
        );
    }
}

export default Button;