import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider } from "react-redux"
import DefaultContainer from './containers/Default'
import store from "./redux/store/index";

import Frontpage from './pages/Frontpage';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import * as serviceWorker from './serviceWorker';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const routing = (
  <Provider store={store}>
    <Router>
      <Switch>
        <RouteWrapper exact path="/" component={Frontpage} layout={DefaultContainer} />
        <RouteWrapper path="/about" component={About} layout={DefaultContainer} />
        <RouteWrapper path="/portfolio" component={Portfolio} layout={DefaultContainer} />
        <RouteWrapper path="/contact" component={Contact} layout={DefaultContainer} />
      </Switch>
    </Router>
  </Provider>
);

function RouteWrapper({component: Component, layout: Layout, ...rest}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();
