import React from 'react';
import Carousel from './Slider';
import ImageScroller from './ImageScroller';

export const PortfolioDetail = (props) => {
    const { title, detailImages, description, technology, detailType } = props.data;
    const handleClose = props.handleClose;

    return (
        <div className="portfolio-detail">
            <div className="portfolio-image">
                {detailType == 'carousel' && (
                    <Carousel images={ detailImages } />
                )}
                {detailType == 'scroller' && (
                    <ImageScroller images={ detailImages } />
                )}
            </div>
            <div className="row mt-2 mb-2">
                <div className="grid grid--lg-8">
                    <h2>{ title }</h2>
                </div>
                <div className="grid grid--lg-4">
                    <button
                        className="btn btn-ghost portfolio-close"
                        onClick={handleClose}>
                            CLOSE
                    </button>
                </div>
            </div>
            <div className="portfolio-description">
                <p>{ description }</p>
            </div>
            {technology && (
                <div className="portfolio-technology">
                    <h3>Technologies Used</h3>
                    <ul className="list-inline">
                        {technology.map((item, index) => (
                            <li key={ index }>
                                { item }
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};